import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const validateSearch = z.object({
  sectionId: z.string().catch(""),
});

export type TypeofValidateSearch = z.infer<typeof validateSearch>;

export const Route = createFileRoute("/_common-layout/exchange/products/")({
  validateSearch,
});
