import { useEffect } from "react";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import axiosInstance from "@/utils/axiosInstance";

const AuthProvider = ({ children }: any) => {
  const router = useRouterState();
  const navigate = useNavigate();

  const registerAxiosInterceptor = () => {
    axiosInstance.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (
          error.response.status === 401 &&
          router.location.pathname !== "/401"
        ) {
          if (error.response && error.response.status === 401) {
            // Redirect the user to the /401 page
            navigate({ to: "/401" });
          }
        }
        return Promise.reject(error);
      }
    );
  };

  useEffect(() => {
    registerAxiosInterceptor();
  }, []);

  return children;
};

export default AuthProvider;
