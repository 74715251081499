/// <reference types="vite-plugin-svgr/client" />

import IcHome from "@/assets/icons/tabs/home.svg?react";
import IcExchange from "@/assets/icons/tabs/exchange.svg?react";
import IcCoupon from "@/assets/icons/tabs/coupon.svg?react";
import IcChallenge from "@/assets/icons/tabs/boots.svg?react";
import { Link, useMatchRoute } from "@tanstack/react-router";
import { Route as homeRoute } from "@/routes/_main-layout/dashboard/index";
import { Route as exchangeRoute } from "@/routes/_main-layout/exchange/index";
import { Route as couponRoute } from "@/routes/_main-layout/coupon/index";
import { Route as challengeRoute } from "@/routes/_main-layout/challenge/index";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { trackTMNCoinClickButton } from "@/api/tracking.api";
import useAppConfigStore from "@/store/useAppConfigStore";
// import IcBoost from '@/assets/icons/tabs/boots.svg?react'
// import { Route as bootsRoute } from '@/routes/_main-layout/boost/index'

const ACTIVE_COLOR = "#FF8724";
const DEFAULT_COLOR = "#878B96";

type MenuButtonProps = {
  path: string;
  label: string;
  isActive?: boolean;
  defaultIcon: () => JSX.Element;
  activeIcon: () => JSX.Element;
  handleClick?: () => void;
  [key: string]: any;
};

const MenuButton = ({
  path,
  label,
  isActive = false,
  defaultIcon,
  activeIcon,
  handleClick,
  ...props
}: MenuButtonProps) =>
  useMemo(
    () => (
      <Link
        {...props}
        params
        to={path}
        className="basis-1/3 pt-[9px] pb-[9px]"
        onClick={handleClick}
      >
        {
          <div className="flex flex-col justify-center items-center text-center">
            {isActive ? activeIcon() : defaultIcon()}
            <span
              className={isActive ? "button-small" : "label"}
              style={{ color: isActive ? ACTIVE_COLOR : DEFAULT_COLOR }}
            >
              {label}
            </span>
          </div>
        }
      </Link>
    ),
    [path, label, isActive, defaultIcon, activeIcon]
  );

const BottomNav = () => {
  const { t } = useTranslation(["translation"]);
  const matchRoute = useMatchRoute();
  const { isEnableChallenge } = useAppConfigStore();
  const isActiveDashboard = matchRoute({ to: homeRoute.to });
  const isActiveExchange = matchRoute({ to: exchangeRoute.to });
  const isActiveCoupon = matchRoute({ to: couponRoute.to });
  const isActiveChallenge = matchRoute({ to: challengeRoute.to });
  return (
    <div
      data-testid="bottom-nav"
      className=" w-full flex flex-row gap-0 border-t border-gray-200 bg-white fixed bottom-0 "
    >
      <MenuButton
        data-testid="dashboard-menu-button"
        path={homeRoute.to}
        label={t("home")}
        isActive={!!isActiveDashboard}
        defaultIcon={() => <IcHome style={{ color: DEFAULT_COLOR }} />}
        activeIcon={() => <IcHome style={{ color: ACTIVE_COLOR }} />}
        handleClick={() => {
          trackTMNCoinClickButton({ buttonName: "Menu home" });
        }}
      />

      <MenuButton
        data-testid="exchange-menu-button"
        path={exchangeRoute.to}
        label={t("redeem")}
        isActive={!!isActiveExchange}
        defaultIcon={() => <IcExchange style={{ color: DEFAULT_COLOR }} />}
        activeIcon={() => <IcExchange style={{ color: ACTIVE_COLOR }} />}
        handleClick={() => {
          trackTMNCoinClickButton({ buttonName: "Menu redeem point" });
        }}
      />

      <MenuButton
        data-testid="coupons-menu-button"
        path={couponRoute.to}
        search={{ type: "received" }}
        label={t("myCoupons")}
        isActive={!!isActiveCoupon}
        defaultIcon={() => <IcCoupon style={{ color: DEFAULT_COLOR }} />}
        activeIcon={() => <IcCoupon style={{ color: ACTIVE_COLOR }} />}
        handleClick={() => {
          trackTMNCoinClickButton({ buttonName: "Menu my coupon" });
        }}
      />

      {isEnableChallenge && (
        <MenuButton
          data-testid="challenge-menu-button"
          path={challengeRoute.to}
          label={t("challenge")}
          isActive={!!isActiveChallenge}
          defaultIcon={() => <IcChallenge style={{ color: DEFAULT_COLOR }} />}
          activeIcon={() => <IcChallenge style={{ color: ACTIVE_COLOR }} />}
          handleClick={() => {}}
        />
      )}
    </div>
  );
};

export default BottomNav;
