import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  beforeLoad: ({ params, search }) => {
    throw redirect({
      to: "/dashboard",
      params,
      search,
    });
  }
});