import { getConfig } from "@/utils/config";
import { createFileRoute, redirect } from "@tanstack/react-router";

const userTierEnabled = getConfig().userTierEnabled;

export const Route = createFileRoute("/_main-layout/tier/")({
  beforeLoad: ({ params, search }) => {
    if (!userTierEnabled) {
      throw redirect({
        to: "/dashboard",
        params,
        search,
      });
    }
  },
});
