import { Outlet, createFileRoute } from "@tanstack/react-router";
import MainLayout from "./_main-layout/-components/MainLayout";

export const Route = createFileRoute("/_main-layout")({
  component: LayoutComponent,
});

function LayoutComponent() {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
}
