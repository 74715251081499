import { createFileRoute } from "@tanstack/react-router";
import * as z from "zod";

const validateSearch = z.object({
  type: z.string().catch(""),
});

export type TypeofValidateSearch = z.infer<typeof validateSearch>;

export const Route = createFileRoute("/_main-layout/coupon/")({
  validateSearch,
});
