import { BrazeToken } from "@/api/common.api";
import * as braze from "@braze/web-sdk";
import { getConfig } from "./config";

export const initBraze = ({ apiKey, restEndpoint }: BrazeToken) => {
  const { enableTrackingDebug } = getConfig();
  braze.initialize(apiKey, {
    baseUrl: restEndpoint,
    enableLogging: enableTrackingDebug,
  });
  return braze;
};

export default braze;
